var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-overlay",
    { attrs: { show: _vm.loadingIndicator, rounded: "sm", variant: "dark" } },
    [
      _c(
        "b-container",
        { staticClass: "b-card-forms" },
        [
          _c(
            "b-row",
            { staticClass: "vh-100", attrs: { "align-h": "center" } },
            [
              _c(
                "b-col",
                {
                  attrs: {
                    "align-self": "center",
                    cols: "12",
                    sm: "9",
                    lg: "5"
                  }
                },
                [
                  _c(
                    "b-card",
                    [
                      _c("img", {
                        staticClass: "webmag-logo-big",
                        attrs: {
                          src: require("@/assets/logo-webmag-big.png"),
                          alt: "webmag logo"
                        }
                      }),
                      _c(
                        "b-alert",
                        {
                          attrs: {
                            variant: "success",
                            show: _vm.emailChangedNotice
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("login.emailChanged")))]
                      ),
                      !_vm.loggedIn &&
                      !_vm.userNotConfirmed &&
                      !_vm.passwordChallenge &&
                      !_vm.mfaChallenge
                        ? _c(
                            "div",
                            [
                              _c("b-alert", {
                                attrs: {
                                  variant: "danger",
                                  show: _vm.errorOnLoginOccurred
                                },
                                domProps: {
                                  innerHTML: _vm._s(_vm.formErrorMsg)
                                }
                              }),
                              _c(
                                "b-form",
                                {
                                  on: {
                                    submit: function($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.onSubmit.apply(null, arguments)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    { attrs: { id: "email-group" } },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "email-input",
                                          name: "email-input",
                                          type: "email",
                                          placeholder: _vm.$t(
                                            "login.placeholderEmail"
                                          ),
                                          state: _vm.validateState("email"),
                                          "aria-describedby":
                                            "email-live-feedback"
                                        },
                                        model: {
                                          value: _vm.$v.form.email.$model,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.$v.form.email,
                                              "$model",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "$v.form.email.$model"
                                        }
                                      }),
                                      _c(
                                        "b-form-invalid-feedback",
                                        {
                                          attrs: { id: "email-live-feedback" }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("login.invalidEmail")
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "position-relative",
                                      attrs: { id: "password-group" }
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "password-input",
                                          name: "password-input",
                                          placeholder: _vm.$t(
                                            "login.placeholderPassword"
                                          ),
                                          type: _vm.showPassword
                                            ? "text"
                                            : "password",
                                          state: _vm.validateState("password"),
                                          "aria-describedby":
                                            "password-live-feedback"
                                        },
                                        model: {
                                          value: _vm.$v.form.password.$model,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.$v.form.password,
                                              "$model",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "$v.form.password.$model"
                                        }
                                      }),
                                      !_vm.showPassword
                                        ? _c("b-icon", {
                                            staticClass:
                                              "position-absolute password-eye",
                                            attrs: {
                                              icon: "eye-slash",
                                              "aria-hidden": "true",
                                              scale: "1"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.togglePasswordVisibility(
                                                  true
                                                )
                                              }
                                            }
                                          })
                                        : _c("b-icon", {
                                            staticClass:
                                              "position-absolute password-eye",
                                            attrs: {
                                              icon: "eye",
                                              "aria-hidden": "true",
                                              scale: "1"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.togglePasswordVisibility(
                                                  false
                                                )
                                              }
                                            }
                                          }),
                                      _c(
                                        "b-form-invalid-feedback",
                                        {
                                          attrs: {
                                            id: "password-live-feedback"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("login.requiredPassword")
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "p-3",
                                      attrs: {
                                        block: "",
                                        type: "submit",
                                        variant: "success"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("login.login")) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c("p", { staticClass: "text-center mt-4" }, [
                                _c("small", [
                                  _vm._v(_vm._s(_vm.$t("login.forgotPassword")))
                                ]),
                                _c(
                                  "small",
                                  [
                                    _c(
                                      "b-link",
                                      {
                                        attrs: { to: "/webmag-password-reset" }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("login.resetPassword"))
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]),
                              _c("p", { staticClass: "text-center mt-4" }, [
                                _c("small", [
                                  _vm._v(
                                    _vm._s(_vm.$t("login.accountQuestion"))
                                  )
                                ]),
                                _c(
                                  "small",
                                  [
                                    _c(
                                      "b-link",
                                      { attrs: { to: "/webmag-signup" } },
                                      [_vm._v(_vm._s(_vm.$t("login.register")))]
                                    )
                                  ],
                                  1
                                )
                              ])
                            ],
                            1
                          )
                        : _vm.mfaChallenge
                        ? _c("mfa-challenge", {
                            attrs: { "aws-user": _vm.awsUser },
                            on: {
                              "verification-number-entered":
                                _vm.mfaChallengeDone
                            }
                          })
                        : _vm.passwordChallenge
                        ? _c("password-challenge", {
                            attrs: { "aws-user": _vm.awsUser }
                          })
                        : !_vm.loadingIndicator
                        ? _c("verification-code-msg", {
                            attrs: { email: _vm.form.email },
                            on: {
                              "verification-success":
                                _vm.verificationNumberEntered,
                              "show-loader": _vm.showLoader
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }